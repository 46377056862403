import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { PUT, GET } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false
    },
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    visit: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    notes: {
      required: true,
      type: [Array, Object],
      default: function _default() {
        return new Array();
      }
    }
  },
  data: function data() {
    return {
      visitNoteActive: false,
      visitNote: null,
      visitNoteDocument: new Array()
    };
  },
  components: {
    FileTemplate: FileTemplate
  },
  methods: {
    createVisitNote: function createVisitNote() {
      var _this = this;

      if (!_this.$refs.visitNoteForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(PUT, {
        url: "job/" + _this.detail.id + "/visit/" + _this.visit.id + "/note",
        data: {
          notes: _this.visitNote,
          documents: _this.visitNoteDocument
        }
      }).then(function (_ref) {
        var data = _ref.data;

        _this.notes.unshift(data); //_this.getVisit(_this.visit);


        _this.closeNote();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    getVisit: function getVisit(id) {
      var _this = this;

      var requestUrl = "job/" + _this.detail.id + "/visit/" + id;

      _this.$store.dispatch(GET, {
        url: requestUrl
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.visitDetail = data;
        _this.visitNotes = data.notes;
        _this.visitDetailDialog = true;
        _this.visitDeleteRequestURL = requestUrl;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    updateFiles: function updateFiles(param) {
      this.visitNoteDocument = param;
    },
    closeNote: function closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteDocument = new Array();
      this.visitNoteActive = false;
    }
  }
};