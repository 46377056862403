import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, POST } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
export default {
  mixins: [CommonMixin],
  props: {
    serviceFormDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    visit: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    isVisitDetail: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.data.visit = this.lodash.toSafeInteger(param.id);
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.data.ticket = this.lodash.toSafeInteger(param.id);
      }
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      formValid: true,
      formLoading: false,
      visitList: new Array(),
      serviceFormList: [{
        label: "Service Support and Incident Form",
        value: "service-form-b1"
      }, {
        label: "Maintenance Form",
        value: "service-form-b2"
      }, {
        label: "Warranty Form",
        value: "service-form-b3"
      }],
      data: {
        service_form: null,
        visit: 0,
        ticket: 0
      }
    };
  },
  methods: {
    updateOrCreate: function updateOrCreate() {
      var _this = this;

      if (!_this.$refs.serviceForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(POST, {
        url: "job/" + _this.detail.id + "/visit/" + _this.data.visit + "/service-form/" + _this.data.service_form
      }).then(function () {
        _this.$emit("create:service-form", true);

        DialogJobEventBus.$emit("load:job", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    getJobVisits: function getJobVisits() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "job/" + _this.detail.id + "/visit"
      }).then(function (_ref) {
        var data = _ref.data;

        if (_this.lodash.isArray(data)) {
          _this.visitList = data.map(function (row) {
            if (row.header) {
              return {
                header: row.header_text
              };
            }

            return row;
          });
        }
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    engineerDisplayText: function engineerDisplayText(param) {
      var _this = this;

      var display_name = new Array();

      if (_this.lodash.isEmpty(param) === false && _this.lodash.isArray(param)) {
        for (var i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }

      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    }
  },
  components: {
    Dialog: Dialog
  },
  mounted: function mounted() {
    var _this = this;

    _this.data.ticket = _this.detail.id;

    _this.getJobVisits();
  }
};