import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import CloserDetail from "@/view/pages/job/partials/Closer-Detail.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false
    },
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    customer: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    property: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    billing: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    contactPerson: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    visitDetail: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    isVisitDetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      addcloserDialog: false,
      closerDialog: false,
      closer: {},
      ticketClosers: [],
      engineerList: [],
      dates: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0
      },
      timeout: null,
      timeoutLimit: 500,
      job: 0,
      closerLoading: false
    };
  },
  components: {
    Barcode: Barcode,
    CloserDetail: CloserDetail,
    CreateTimeCloser: CreateTimeCloser
  },
  watch: {
    visitDetail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getTicketCloser();
        this.getEngineerList();
        this.getVisitList();
      }
    }
  },
  methods: {
    detailTicketCloser: function detailTicketCloser(row) {
      this.closerDialog = true;
      this.closer = row;
    },
    createdTimeCloser: function createdTimeCloser() {
      this.addcloserDialog = false;
      this.getTicketCloser();
      DialogJobEventBus.$emit("load:job", true);
    },
    getTicketCloser: function getTicketCloser() {
      var _this = this;

      var filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit("update:error", InitializeError("End Date must be after Start Date."));
          return false;
        }
      }

      clearTimeout(_this.timeout);
      _this.closerLoading = true;
      _this.timeout = setTimeout(function () {
        _this.getClosers(_this.job, {
          filter: filter
        }).then(function (response) {
          _this.ticketClosers = response;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.closerLoading = false;
        });
      }, _this.timeoutLimit);
    },
    getEngineerList: function getEngineerList() {
      var _this = this;

      if (_this.isVisitDetail) {
        return false;
      }

      _this.getEngineers(_this.job).then(function (_ref) {
        var rows = _ref.rows;

        if (_this.lodash.isEmpty(rows) === false) {
          _this.engineerList = rows.map(function (row) {
            return row.engineer;
          });
        }
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    getVisitList: function getVisitList() {
      var _this = this;

      if (_this.isVisitDetail) {
        return false;
      }

      _this.getVisitsForFilter(_this.job).then(function (response) {
        _this.visitList = response;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    engineerDisplayText: function engineerDisplayText(param) {
      var _this = this;

      var display_name = new Array();

      if (_this.lodash.isEmpty(param) === false && _this.lodash.isArray(param)) {
        for (var i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }

      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = [];
          break;
      }

      _this.getTicketCloser();
    }
  },
  mounted: function mounted() {
    this.getTicketCloser();
    this.getEngineerList();
    this.getVisitList();
  }
};