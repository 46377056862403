//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ServiceReportServiceForm from "@/view/pages/service-form/genic-teams/Service-Report.vue";
import WorkOrderServiceForm from "@/view/pages/service-form/genic-teams/Work-Order.vue";
export default {
  components: {
    ServiceReportServiceForm: ServiceReportServiceForm,
    WorkOrderServiceForm: WorkOrderServiceForm
  },
  props: {
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    serviceForm: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  computed: {
    getComponent: function getComponent() {
      var component = null;

      if (this.lodash.isEmpty(this.serviceForm) === false) {
        switch (this.lodash.toLower(this.serviceForm.service_form)) {
          case "service-report":
            {
              component = ServiceReportServiceForm;
              break;
            }

          case "work-order":
            {
              component = WorkOrderServiceForm;
              break;
            }
        }
      }

      return component;
    }
  }
};