<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.visit.status == 7 }"
  >
    <v-row>
      <v-col md="12" class="pt-0">
        <div class="d-none">
          <v-text-field
            type="hidden"
            v-model="pageLoading"
            :rules="[validateRules.required(!pageLoading)]"
          >
          </v-text-field>
        </div>
        <label class="font-weight-700 font-size-16">
          <v-text-field
            dense
            filled
            color="cyan"
            solo
            flat
            :disabled="pageLoading"
            :loading="pageLoading"
            hide-details
            class="mr-2 hide-border"
          >
          </v-text-field>
        </label>

        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 85vh; position: relative"
        >
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    CU #
                  </h3>
                </v-col>
                <template>
                  <v-col md="6" class="text-right py-0">
                    <!-- <v-btn
                      small
                      class="text-white mr-5"
                      color="cyan"
                      v-on:click="addchecklistone"
                    >
                      <span class="font-size-16 font-weight-600">Add</span>
                    </v-btn> -->
                    <v-btn
                      v-if="false"
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateOrcreate"
                    >
                      <span class="font-size-16 font-weight-600">Save</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-12 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <template
                v-if="attributes && attributes.cu && attributes.cu.length > 0"
              >
                <v-row v-for="(type, index) in attributes.cu" :key="index">
                  <template>
                    <v-col md="2" class="pb-4">
                      <label>Brand </label>
                      <v-text-field
                        v-model.trim="type.product"
                        dense
                        readonly
                        filled
                        label="Brand"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="pb-4">
                      <label>Model</label>
                      <v-text-field
                        v-model.trim="type.eq_model"
                        dense
                        filled
                        readonly
                        label="Model"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="pb-4">
                      <label>Serial No.</label>
                      <v-text-field
                        v-model.trim="type.serial_no"
                        dense
                        filled
                        readonly
                        label="Serial No."
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="pb-4">
                      <label>Suction </label>
                      <v-text-field
                        v-model.trim="type.suction"
                        dense
                        filled
                        label="Suction"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="pb-4">
                      <label>Dishcharge ampere </label>
                      <v-text-field
                        v-model.trim="type.discharge_amphere"
                        dense
                        filled
                        label="Dishcharge ampere"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="pb-4">
                      <label>Job Location </label>
                      <v-text-field
                        v-model.trim="type.location"
                        dense
                        filled
                        readonly
                        label="Job Location"
                        solo
                        flat
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="1"
                      class="pb-4 mt-6"
                      v-if="attributes.cu.length > 1"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-icon
                        v-on:click="deleteCommon_one(index)"
                        color="deep-orange"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-delete</v-icon
                      > -->
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </v-col>
                  </template>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 85vh; position: relative"
        >
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    FCU #
                  </h3>
                </v-col>
                <template>
                  <v-col md="6" class="text-right py-0">
                    <!-- <v-btn
                      small
                      class="text-white mr-5"
                      color="cyan"
                      v-on:click="addchecklisttwo"
                    >
                      <span class="font-size-16 font-weight-600">Add</span>
                    </v-btn> -->
                    <v-btn
                      v-if="false"
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateOrcreate"
                    >
                      <span class="font-size-16 font-weight-600">Save</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-12 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row v-for="(type, index) in attributes.fcu" :key="index">
                <template>
                  <v-col md="2" class="pb-4">
                    <label>Brand</label>
                    <v-text-field
                      v-model.trim="type.product"
                      dense
                      filled
                      label="Brand"
                      solo
                      readonly
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" class="pb-4">
                    <label>Model</label>
                    <v-text-field
                      v-model.trim="type.eq_model"
                      dense
                      filled
                      label="Model"
                      solo
                      readonly
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" class="pb-4">
                    <label>Serial No.</label>
                    <v-text-field
                      v-model.trim="type.serial_no"
                      dense
                      filled
                      readonly
                      label="Serial No."
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" class="pb-4">
                    <label>Temperature off Coil</label>
                    <v-text-field
                      v-model.trim="type.temperature_off_oil"
                      dense
                      filled
                      label="Temperature off Coil"
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" class="pb-4">
                    <label>Temperature on Coil</label>
                    <v-text-field
                      v-model.trim="type.temperature_on_oil"
                      dense
                      filled
                      label="Temperature on Coil"
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" class="pb-4">
                    <label>Job Location </label>
                    <v-text-field
                      v-model.trim="type.location"
                      dense
                      filled
                      label="Job Location"
                      solo
                      readonly
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="1"
                    class="pb-4 mt-6"
                    v-if="attributes.fcu.length > 1"
                  >
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-icon
                        v-on:click="deleteCommon_two(index)"
                        color="deep-orange"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-delete</v-icon
                      > -->
                      </template>
                      <span>Click here to delete</span>
                    </v-tooltip>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </perfect-scrollbar>
        <!-- <v-row dense>
          <v-col
            md="6"
            v-for="(row, key) in attributes.checklist.checkbox"
            :key="key"
          >
            <v-text-field
              dense
              filled
              color="cyan"
              :label="row.label"
              v-model="row.label"
              :disabled="pageLoading"
              :loading="pageLoading"
              solo
              flat
              hide-details
            >
              <template v-slot:prepend>
                <v-checkbox
                  :disabled="pageLoading"
                  :value="1"
                  color="cyan"
                  class="hide-margin small"
                  hide-details
                  dense
                  v-model="row.value"
                ></v-checkbox>
              </template>
            </v-text-field>
          </v-col>
        </v-row> -->
      </v-col>
      <v-col md="6">
        <label class="font-weight-700 font-size-16">Fault Found</label>
        <v-autocomplete
          :items="faultdata"
          dense
          filled
          color="cyan"
          item-color="cyan"
          solo
          flat
          v-model="attributes.fault_found"
          placeholder="Select Fault"
          v-on:click:append-outer="manageBrandDialog = true"
          v-on:change="attributes.fault_found_other = attributes.fault_found"
          item-text="title"
          item-value="title"
          class="width-100"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-html="'No Option(s) Found.'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <template>
          <Fault_found
            :dialog="manageBrandDialog"
            :e_designation="faultdata"
            v-on:get-faults="getOptions"
            v-on:close-dialog="manageBrandDialog = false"
          ></Fault_found>
        </template>
      </v-col>
      <!-- <v-col md="6">
        <label class="font-weight-700 font-size-16">Remedy Action </label>
        <v-textarea v-model="attributes.remedy_action" auto-grow dense filled :disabled="pageLoading"
          :loading="pageLoading" color="cyan" label="Description" solo flat row-height="12"></v-textarea>
         <v-text-field
          auto-grow
          dense
          filled
          color="cyan"
          label="Remedy Action"
          v-model="attributes.remedy_action"
          :disabled="pageLoading"
          :loading="pageLoading"
          solo
          flat
          hide-details
          row-height="25"
        ></v-text-field> 
      </v-col>-->
      <v-col md="6">
        <label class="font-weight-700 font-size-16">Fault Found Others </label>
        <v-text-field
          auto-grow
          dense
          filled
          color="cyan"
          label="Others"
          v-model="attributes.fault_found_other"
          :disabled="pageLoading"
          :loading="pageLoading"
          solo
          flat
          hide-details
          row-height="25"
        ></v-text-field>
      </v-col>

      <template>
        <v-col md="6">
          <table class="line-item-multiple-table" width="100%">
            <thead>
              <tr>
                <th class="text-center" width="5%">Remedy</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="attributes && attributes.remedy_action">
                <template
                  v-for="(attribute, index) in attributes.remedy_action"
                >
                  <tr v-if="checkSelected(attribute)" :key="index">
                    <td valign="top" class="text-center" width="5%">
                      <v-checkbox
                        color="cyan"
                        hide-details
                        :ref="`line-item-${index}-row`"
                        class="m-0 p-0 mt-2"
                        v-model="attribute.isChecked"
                        :disabled="searching"
                      ></v-checkbox>
                    </td>
                    <td valign="top" class="text-left" width="50%">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="attribute.remedy"
                      ></v-text-field>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        flat
                        :disabled="searching"
                        v-model="attribute.description"
                        label="Description"
                        solo
                        color="cyan"
                        row-height="10"
                        v-if="attribute.isChecked"
                      ></v-textarea>
                      <v-textarea
                        v-else
                        auto-grow
                        dense
                        filled
                        flat
                        :disabled="searching || !attribute.isChecked"
                        label="Description"
                        solo
                        color="cyan"
                        row-height="10"
                      ></v-textarea>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </v-col>
      </template>

      <template>
        <v-col md="6">
          <table class="line-item-multiple-table" width="100%">
            <thead>
              <tr>
                <th class="text-center" width="5%">Recommendation</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="attributes && attributes.recommendation">
                <template
                  v-for="(attribute, index) in attributes.recommendation"
                >
                  <tr v-if="checkSelected(attribute)" :key="index">
                    <td valign="top" class="text-center" width="5%">
                      <v-checkbox
                        color="cyan"
                        hide-details
                        :ref="`line-item-${index}-row`"
                        v-model="attribute.isChecked"
                        class="m-0 p-0 mt-2"
                      ></v-checkbox>
                    </td>
                    <td valign="top" class="text-left" width="50%">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        readonly
                        v-model="attribute.recommendation"
                        color="cyan"
                      ></v-text-field>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        flat
                        v-model="attribute.description"
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                        v-if="attribute.isChecked"
                      ></v-textarea>
                      <v-textarea
                        v-else
                        auto-grow
                        dense
                        filled
                        flat
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                      ></v-textarea>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </v-col>
      </template>

      <template>
        <v-col md="6">
          <table class="line-item-multiple-table" width="100%">
            <thead>
              <tr>
                <th class="text-center" width="5%">Remark</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="attributes && attributes.remark">
                <template v-for="(attribute, index) in attributes.remark">
                  <tr v-if="checkSelected(attribute)" :key="index">
                    <td valign="top" class="text-center" width="5%">
                      <v-checkbox
                        color="cyan"
                        hide-details
                        :ref="`line-item-${index}-row`"
                        class="m-0 p-0 mt-2"
                        v-model="attribute.isChecked"
                      ></v-checkbox>
                    </td>
                    <td valign="top" class="text-left" width="50%">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="attribute.remark"
                      ></v-text-field>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        flat
                        v-model="attribute.description"
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                        v-if="attribute.isChecked"
                      ></v-textarea>
                      <v-textarea
                        v-else
                        auto-grow
                        dense
                        filled
                        flat
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                      ></v-textarea>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </v-col>
      </template>

      <template>
        <v-col md="6">
          <table class="line-item-multiple-table" width="100%">
            <thead>
              <tr>
                <th class="text-center" width="5%">Follow Up</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="attributes && attributes.folowup">
                <template v-for="(attribute, index) in attributes.folowup">
                  <tr v-if="checkSelected(attribute)" :key="index">
                    <td valign="top" class="text-center" width="5%">
                      <v-checkbox
                        color="cyan"
                        hide-details
                        :ref="`line-item-${index}-row`"
                        v-model="attribute.isChecked"
                        class="m-0 p-0 mt-2"
                      ></v-checkbox>
                    </td>
                    <td valign="top" class="text-left" width="50%">
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        readonly
                        color="cyan"
                        v-model="attribute.follow_up"
                      ></v-text-field>
                      <v-textarea
                        auto-grow
                        dense
                        filled
                        flat
                        v-model="attribute.description"
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                        v-if="attribute.isChecked"
                      ></v-textarea>
                      <v-textarea
                        v-else
                        auto-grow
                        dense
                        filled
                        flat
                        label="Description"
                        solo
                        color="cyan"
                        hide-details
                        row-height="10"
                      ></v-textarea>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </v-col>
      </template>

      <!-- <v-col md="6">
        <label class="font-weight-700 font-size-16">Recommondation </label>
        <v-text-field auto-grow dense filled color="cyan" label="Recommondation" v-model="attributes.recommendation"
          :disabled="pageLoading" :loading="pageLoading" solo flat hide-details row-height="25"></v-text-field>
      </v-col>
      <v-col md="6">
        <label class="font-weight-700 font-size-16">
          Remarks
        </label>
        <v-text-field auto-grow dense filled color="cyan" label="Remarks" v-model="attributes.remark"
          :disabled="pageLoading" :loading="pageLoading" solo flat hide-details row-height="25"></v-text-field>
      </v-col> -->
      <v-col md="6"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import DateTimePicker from "@/view/pages/partials/DateTimePicker";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH, QUERY, GET } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Fault_found from "@/view/pages/partials/Fault_found_service-report.vue";

export default {
  name: "fault_faund",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      checkbox1: true,
      checkbox2: false,
      show_selected: false,
      fault: null,
      manageBrandDialog: false,
      faultdata: [],
      remedy_action: [],
      line_items: [],
      attributes: {
        cu: [
          {
            product: null,
            suction: null,
            eq_model: null,
            location: null,
            serial_no: null,
            discharge_amphere: null,
          },
        ],
        fcu: [
          {
            product: null,
            eq_model: null,
            location: null,
            serial_no: null,
            temperature_on_oil: null,
            temperature_off_oil: null,
          },
        ],
        fault_found_other: null,
        fault_found: null,
        fault_found_text: null,
        remedy_action: [
          {
            remedy: null,

            isChecked: true,
            description: null,
          },
        ],
        recommendation: [
          {
            recommendation: null,

            isChecked: true,
            description: null,
          },
        ],
        remark: [
          {
            remark: null,

            isChecked: true,
            description: null,
          },
        ],
      },
    };
  },
  components: {
    Fault_found,
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "setting/getOptions-fault-found",
        })
        .then(({ data }) => {
          _this.faultdata = this.lodash.map(data.fault_found, function (row) {
            return {
              title: row.text,
              value: row.id,
            };
          });
          _this.leaveTypes = this.lodash.map(data.leave_types, function (row) {
            return {
              title: row.value,
              value: row.id,
            };
          });
          _this.engineerSkills = this.lodash.map(
            data.ticket_types,
            function (row) {
              return {
                title: row.value,
                value: row.value.toLowerCase(),
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    checkSelected(attribute) {
      if (this.show_selected) {
        return attribute.status;
      }
      return true;
    },

    getVisitLineItems() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "line-item/new",
              data: {
                job: this.detail.id,
              },
            })
            .then(({ data }) => {
              let line_items = data;
              _this.line_items = line_items;
              let checklist_one = [];
              let checklist_two = [];
              this.attributes = this.serviceForm.attributes;
              this.attributes.fault_found = this.lodash.toSafeInteger(
                this.serviceForm.attributes.fault_found
              );

              let equipmentArr = this.lodash.filter(line_items, function (row) {
                return row.product_type == "equipment";
              });
              checklist_one = this.lodash.filter(equipmentArr, function (row) {
                return row.cu == "1";
              });
              checklist_two = this.lodash.filter(equipmentArr, function (row) {
                return row.fcu == "1";
              });
              if (!this.serviceForm.is_filled) {
                _this.attributes.cu = checklist_one;
                _this.attributes.fcu = checklist_two;
              } else {
                if (checklist_one.length < 1) {
                  _this.attributes.cu = [];
                }
                if (checklist_two.length < 1) {
                  _this.attributes.fcu = [];
                }
              }
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    updateVisitMobileSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.job_recommendation_mobile = _this.job_recommendation_mobile.map(
        (row) => {
          return { recommendation: row.recommendation };
        }
      );
      _this.$store
        .dispatch(PATCH, {
          url: "line-item/update-visit-mobile",
          data: { job_recommendation_mobile: _this.job_recommendation_mobile },
        })

        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getLineItemMobile() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/mobile-app" })
        .then(({ data }) => {
          let { follow_up, recommendation, remark, remedy } = data;

          console.log(this.serviceForm.attributes);
          /* this.attributes = this.serviceForm.attributes;
            this.attributes.fault_found = this.lodash.toSafeInteger(this.serviceForm.attributes.fault_found);

            this.attributes.remedy_action = this.lodash.toSafeInteger(this.serviceForm.attributes.remedy_action);
            this.attributes.recommendation = this.lodash.toSafeInteger(this.serviceForm.attributes.recommendation);
            this.attributes.remark = this.lodash.toSafeInteger(this.serviceForm.attributes.remark);
            this.attributes.folowup = this.lodash.toSafeInteger(this.serviceForm.attributes.folowup); */
          if (this.serviceForm.attributes) {
            _this.attributes.remedy_action =
              this.serviceForm.attributes.remedy_action;
            _this.attributes.remark = this.serviceForm.attributes.remark;
            _this.attributes.recommendation =
              this.serviceForm.attributes.recommendation;
            _this.attributes.folowup = this.serviceForm.attributes.folowup;
          } else {
            _this.attributes.remedy_action = remedy;
            _this.attributes.remark = remark;
            _this.attributes.recommendation = recommendation;
            _this.attributes.folowup = follow_up;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            //_this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },

    // deleteCommon_one(index) {
    //   if(index > 0){
    //       this.attributes.checklist_one.splice(index, 1);
    //       }
    //   },
    // deleteCommon_two(index) {
    //   if(index > 0){
    //       this.attributes.checklist_two.splice(index, 1);
    //       }
    //   },

    saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },

  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted() {
    const _this = this;
    _this.getVisitLineItems();
    this.getLineItemMobile();
    _this.getOptions();
    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      _this.saveServiceForm(param);
    });

    _this.updatePreInformation();
  },
};
</script>
<style scoped>
@media (min-width: 960px) {
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 15.07% !important;
    max-width: 16.666667%;
  }
}
</style>
