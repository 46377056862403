import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DateTimePicker from "@/view/pages/partials/DateTimePicker";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "work-order",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    serviceForm: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  data: function data() {
    return {
      pageLoading: true,
      attributes: {
        checklist: {
          label: "Check List",
          checkbox: [{
            label: "Clean Motor & Controller Compartment",
            value: null
          }, {
            label: "Clean Frame & Console Panel",
            value: null
          }, {
            label: "Check & Test Console For Abnormality",
            value: null
          }, {
            label: "Clean & Lubricate Running Deck",
            value: null
          }, {
            label: "Check Running Belt Alignment & Adjust Accordingly",
            value: null
          }, {
            label: "Check Running Belt Tightness & Adjust if Necessary",
            value: null
          }]
        },
        textarea_one: {
          label: "Service Rendered",
          value: null
        },
        textarea_two: {
          label: "Engineer Remarks",
          value: null
        },
        dates: {
          started_at: {
            label: "Started At",
            value: null
          },
          finished_at: {
            label: "Finished At",
            value: null
          }
        },
        terms_conditions: {
          label: "Terms & Conditions",
          value: null
        }
      }
    };
  },
  components: {
    DateTimePicker: DateTimePicker
  },
  methods: {
    updatePreInformation: function updatePreInformation() {
      var _this = this;

      try {
        console.log({
          serviceForm: _this.serviceForm
        });

        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },
    saveServiceForm: function saveServiceForm(action) {
      var _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store.dispatch(PATCH, {
        url: "job/" + _this.detail.id + "/visit/" + _this.serviceForm.visit.id + "/service-form/" + _this.serviceForm.id,
        data: {
          attributes: _this.attributes,
          action: action
        }
      }).then(function (_ref) {
        var data = _ref.data;
        ServiceFormEventBus.$emit("save:service-form", data);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        ServiceFormEventBus.$emit("close:loader");
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted: function mounted() {
    var _this = this;

    ServiceFormEventBus.$on("start:save-service-form", function (param) {
      _this.saveServiceForm(param);
    });

    _this.updatePreInformation();
  }
};