import "core-js/modules/es.object.to-string.js";
import { QUERY, PATCH } from "@/core/services/store/request.module";
export default {
  methods: {
    getJob: function getJob(job) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getLineItems: function getLineItems(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/line-items",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getEngineers: function getEngineers(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/engineers",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getRecurrence: function getRecurrence(job) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/recurrence"
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getDocuments: function getDocuments(job) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/documents"
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getVisits: function getVisits(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/visits",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getVisitsForFilter: function getVisitsForFilter(job) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/visits/filter"
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getInvoiceReminders: function getInvoiceReminders(job) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/invoice-reminders"
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getServiceForms: function getServiceForms(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/service-forms",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getTimesheets: function getTimesheets(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/timesheets",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getClosers: function getClosers(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(QUERY, {
              url: "job/" + job + "/closers",
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    updateStatus: function updateStatus(job, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          if (job) {
            _this.$store.dispatch(PATCH, {
              url: "job/" + job,
              data: data
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};